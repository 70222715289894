import React from 'react';
import "./messenger.css";
// import Topbar from "../../components/topbar/Topbar";
import Conversation from "./components/conversations/Conversation";
import Message from "./components/message/Message";
// import ChatOnline from "./components/chatOnline/ChatOnline";

// import UsersList from '../user/components/UsersList';
// import ErrorModal from '../shared/components/UIElements/ErrorModal';
// import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SendIcon from '@mui/icons-material/Send';
import { useHttpClient } from '../shared/hooks/http-hook';

import { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';

// import { AuthContext } from "../../context/AuthContext";
import { AuthContext } from '../shared/context/auth-context';
// import axios from "axios";
import { io } from "socket.io-client";
import { IconButton } from '@mui/material';
// const socket = io('http://localhost:5000')
// const socket = io('https://www.driveandship.com/socket.io/')
// for production:
const socket = io('https://www.driveandship.com')

export default function Messenger() {
  const userProfile = useSelector((state) => state.ui.userProfile);

  const [conversations, setConversations] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  // const socket = useRef();
  const { userId } = useContext(AuthContext);
  const user = 'userDEMO';
  const scrollRef = useRef();
  const auth = useContext(AuthContext);
  
  
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedUsers, setLoadedUsers] = useState();
  const [usersConvInChat, setUsersConvInChat] = useState([]);
  const [messagesFromSocket, setMessagesFromSocket] = useState([]);
  const [currentWindow, setCurrentWindow] = useState('conversation');
  let messagesContainer = document.querySelector('.chatBoxTop');
  useEffect(() => {
    console.log("usersConvInChat:", usersConvInChat)
  }, [usersConvInChat]);
  useEffect(() => {
    console.log("currentChat:", currentChat)
  }, [currentChat]);


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(
          '/api/users'
        );

        setLoadedUsers(responseData.users);
        console.log('loadedUsers::',responseData)
      } catch (err) {}
    };
    fetchUsers();
  }, []);


  useEffect(() => {
    if(userId) {

      const getUsersInChat = async () => {
        try {
          const responseData = await sendRequest(
            `/api/conversation/getusersinchat/${userId}`
          );
          console.log('${userId}:',userId)
          // setMessages([]);
          // console.log('responseData: ', responseData.conversation)
          
          // let i = 1;
          console.log('usersInChat: ', responseData.members)
          let allUsersInChat = responseData.members;
          setUsersConvInChat([]);
          // allUsersInChat
          console.log('setUsersInChat: ', allUsersInChat)
          allUsersInChat.forEach(element => {
            setUsersConvInChat(prev => [...prev,responseData.members])
            // setUsersInChat((prev) => ['userDEMO']);
            // console.log(element )
          });
          // setUsersConvInChat2('tttt')
          // console.log('usersConvInChat: ', usersConvInChat2 )
          
          // for(const currentInArrObj of responseData.conversation){
          //   // await setMessages([responseData.conversation]);
          //   setUsersInChat((prev) => [...prev, currentInArrObj]);
          //   console.log("for of:" + currentInArrObj);
          //   console.log("#" + i);
          //   for(const key in currentInArrObj){
          //     console.log(`${key} => ${currentInArrObj[key]}`);
          //   }
          //   i++;
          // }
        } catch (err) { console.log('err!!!',err) }
        // try {setUsersConvInChat2('yyyyy')
        // console.log('usersConvInChat: ', usersConvInChat2 )} catch (err) { console.log('erryyyy!!!',err) }
      };
      getUsersInChat();
      // setUsersConvInChat(['2','2'])
      //     console.log('usersConvInChat2: ', usersConvInChat )
    }
    
  }, [userId]);



  useEffect(() => {
    socket.on('resive-message', (message, senderUserId) => {
      console.log('resive-message::', message, senderUserId)
      const messageNEW = {
        sender: senderUserId,
        text: message,
        conversationId: '45645654',
      };
      setMessages((prev) => [...prev, messageNEW]);
      
      // setMessagesFromSocket(oldArray => [...oldArray, message]);
      if(messages.length > 1) {
      let messagesContainer = document.querySelector('.chatBoxTop');
    messagesContainer.scrollTop = messagesContainer.scrollHeight;
      }
    })
  }, [socket]);


  useEffect(() => {
    if(messages.length > 1 && currentWindow === 'messages') {
      let messagesContainer = document.querySelector('.chatBoxTop');
      console.log('messagesContainer: ' , messagesContainer)
      console.log('messages: ' , messages)
    messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, [messages]);



  useEffect(() => {
    const getMessagesFromConversation = async () => {
      try {
        console.log('currentChat::' , currentChat)
        const responseData = await sendRequest(
          `/api/conversation/getconversation/${currentChat.id}/${userId}`
        );
        setMessages([]);
        console.log('responseData: ', responseData.conversation)
        
        let i = 1;
        console.log('messages: ', messages)
        for(const currentInArrObj of responseData.conversation){
          // await setMessages([responseData.conversation]);
          setMessages((prev) => [...prev, currentInArrObj]);
          console.log("for of:" + currentInArrObj);
          console.log("#" + i);
          for(const key in currentInArrObj){
            console.log(`${key} => ${currentInArrObj[key]}`);
          }
          i++;
        }
        // setUsersConvInChat2('ggg');
        // console.log('usersConvInChat222:',usersConvInChat2);
      } catch (err) {}
    };
    getMessagesFromConversation();
    //  setUsersInChat(['3','3'])
    //       console.log('usersInChat3: ', usersInChat )
  }, [currentChat]);


  useEffect(() => {
    currentChat &&  console.log('currentChat::',currentChat);
    
    arrivalMessage &&
      currentChat?.members.includes(arrivalMessage.sender) &&
      setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);


  const handleSubmit = async (e) => {
    console.log("newMessage", newMessage.length)
    setNewMessage('')
    if(newMessage.length < 1){
      return
    }
    e.preventDefault();
    const message = {
      sender: userId,
      text: newMessage,
      conversationId: currentChat._id,
    };
    socket.emit('send-message', newMessage, userId)
    setMessages((prev) => [...prev, message]);
      try {
        let members = [userId,currentChat.id];
        let conversation = {
          sender: userId,
          resiver: currentChat.id,
          image: currentChat.image,
          name: currentChat.name
          ,text: newMessage,
          date: new Date 
        };
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Bearer ' + auth.token);

        await sendRequest('/api/conversation/createconversation', 'POST',
         JSON.stringify({
          members: members,
          conversation: conversation
        }),
        myHeaders
        );
      } catch (err) {console.log(err)}
  
  };


  return (
    <>
      {/* <Topbar /> */}
      <div className="messenger">
        {currentWindow === 'conversation' &&
        <div className="chatMenu" style={{background: '#e6e6e6'}}>
          <div className="chatMenuWrapper">
            <input placeholder="Search for friends" className="chatMenuInput" />


            {/* <ErrorModal error={error} onClear={clearError} />
              {isLoading && (
                <div className="center">
                  <LoadingSpinner />
                </div>
              )}
              {!isLoading && loadedUsers && 
              <UsersList items={loadedUsers}
              />} */}


            {/* {conversations.map((c) => ( */}
              {usersConvInChat && currentWindow === 'conversation' && usersConvInChat.map((c, index) => (
                <div onClick={() => {
                  setCurrentChat({
                    id: c[index].member,
                    name: c[index].name,
                    membersImage: c[index].member_id ? c[index].member_id.imageOne : null,
                  })
                    console.log('ccc:', c)
                    setCurrentWindow('messages')
                    
                  }}>
                {/* //   <Conversation conversation={c} currentUser={user} /> */}
                   <Conversation
                    conversation={c}
                    image={c[index].member_id ? c[index].member_id.imageOne : null}
                    name={c[index].name}
                    id={c[index].member}
                    text={c[index].text}
                    currentUser={user}
                   />
                   {/* <div><h1>{console.log('c:', c)}rrr{c[index].name}</h1></div> */}
                </div>
              ))}
            {loadedUsers && loadedUsers.map((c, index) => (
              <div onClick={() => {
                setCurrentChat({
                  id: c.id,
                  name: c.name,
                  membersImage: c.imageOne ? c.imageOne : null,
                })
                console.log('ccc:', c)
                setCurrentWindow('messages')
              }
              }
              >
                
                {/* <Conversation conversation={c} currentUser={user} /> */}
                {/* <Conversation
                    conversation={c}
                    image={c[index].member_id ? c[index].member_id.imageOne : null}
                    name={c[index].name}
                    id={c[index].member}
                    text={c[index].text}
                    currentUser={user}
                   /> */}
                <Conversation
                conversation={c} 
                name={c.name}
                id={c.id}
                currentUser={user}
                image={c.imageOne ? c.imageOne : null}
                />
              </div>
            ))}
          </div>
        </div>
        }
        {currentWindow === 'messages' &&
        <div className="chatBox" style={{background: '#e6e6e6'}}>
          <div className="chatBoxWrapper">
            <div style={{
                width: '100%',
                display: 'flex',
                height: '100px',
                background: '#1976d2',
                color: '#fff',
                fontSize: '26px',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}>
              <div 
              onClick={() => setCurrentWindow('conversation')}
              style={{
                margin: '0px 4px',
                color: '#fff',
                fontSize: '26px',
              }}
              >
                <IconButton style={{fontSize: '33px', color:' #fff', background: '#002e5cfc'}}>
                  <ChevronLeftIcon />
                </IconButton>
          </div>
              <div style={{
                margin: '0px 7px',
                paddingTop: '14px'
              }}>
                <img className="messageImg"
                  src={currentChat.membersImage}
                    alt=""
                />
              </div>
              <div style={{fontSize: '13px'}}>{currentChat.name}</div>
            </div>
            {currentWindow === 'messages' && currentChat ? (
              <>
                <div className="chatBoxTop" id='chatBoxTop'>
                {/* {messagesFromSocket.map((msg) => {
                  return (
                    <div>
                      {msg}
                    </div>
                  )})
                  } */}
                  {messages.map((m) => (
                    <div ref={scrollRef}>
                      {/* {m.sender} */}
                      {/* <Message message={m} own={m.sender === user._id} /> */}
                      <Message 
                      message={m}
                      own={m.sender === userId}
                      membersImage={currentChat.membersImage}
                      membersName={currentChat.name}
                      ownImage={userProfile.imageOne}
                      ownName={userProfile.name}
                      />
                    </div>
                  ))}
                </div>
                  {/* <div className="chatBoxBottom">
                    <h5>current-user-id - {userId}</h5>
                    <h6>friend-user-id - {currentChat.id}</h6>
                  </div> */}
                <div className="chatBoxBottom">
                  <textarea
                    className="chatMessageInput"
                    placeholder="write something..."
                    onChange={(e) => setNewMessage(e.target.value)}
                    value={newMessage}
                  ></textarea>
                  {/* <button className="chatSubmitButton" onClick={handleSubmit}>
                    Send
                  </button> */}
                  <IconButton 
                    onClick={handleSubmit}
                    style={{
                      fontSize: '28px',
                      margin: '10px',
                      color: 'rgb(25 118 210)',
                      background: 'white',
                      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 81%), 0px 4px 5px 0px rgb(205 205 205 / 48%), 0px 1px 10px 0px rgb(134 134 134 / 38%)'
                    }}>
                    <SendIcon fontSize='23px' />
                  </IconButton>
                  
                </div>
              </>
            ) : (
              <span className="noConversationText">
                Open a conversation to start a chat.
                {/* {currentWindow} */}
              </span>
            )}
          </div>
        </div>
        }
        {/* <div className="chatOnline">
          <div className="chatOnlineWrapper">
            <ChatOnline
              onlineUsers={onlineUsers}
              currentId={userId}
              setCurrentChat={setCurrentChat}
            />
          </div>
        </div> */}
      </div>
    </>
  );
}